import { m } from "mithril";
import JSONViewer from '../lib/jsonViewer';
import { get } from "../lib/fiona";

var objViewer = false,
obj = {},
objType, objId;

const setGridData = () => {
	if(objViewer){
        objViewer.showJSON(obj, -1, 1);
	}
}

const  syncData = async (force = false) => {
	if(!obj || force){
        // console.log('refreshing obj', objType);
        let reqData = {};
        reqData[objType+'Id'] = objId;
        reqData.raw = true;

		obj = await get(objType, reqData);
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    // oninit: () => {},
	oncreate: ({ attrs: { type, id }}) => {
        // console.log('raw', type, id);
        objType = type;
        objId = id;

        objViewer = new JSONViewer();
        document.getElementById("jsonViewer").appendChild(objViewer.getContainer());
		syncData(true);
	},
	view: () => {
		return m('#jsonViewer');
	}

};