import { m, route } from "mithril"
import { Button, Col, ControlGroup, Grid, Icons, Input } from "construct-ui"
import Tabulator from "tabulator-tables"
import { get } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";

var grid = false,
filter = "",
list = [];

const onFilter = (ev) => {
	filter = ev.target.value;
	grid.setFilter("name", "like", ev.target.value);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
		}).catch(function(err){
			console.error(err);
		});
	}
}

const  syncData = async (force = false) => {
	if(!list.length || force == 'reload'){
        // console.log('refreshing list');
		grid.clearData();
		list = await get('editions', {'editiontypeId': state.get('editiontypeId'), cache: force});
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    oninit: () => {
        if(!state.get('editiontypeId')){
            route.set("/editiontypes/");
        }
    },
	oncreate: () => {
        // console.log('editiontypeId', state.get('editiontypeId'));

		grid = new Tabulator("#grid", {

            persistenceID:"editions",

            responsiveLayout:  true,
			persistence: {
                sort: true,
                filter: true
            },
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer:300,
			rowClick:function(e, row){
				state.set('editionId', row._row.data.id);
				state.set('editionName', row._row.data.name);

				route.set("/films/");
			},
			columns: [
				{title:"id", field:"id"},
                {title:"year", field:"year"},
				{title:"name", field:"name"},
				{title:"start", field:"startFestivalEvent", formatter:"datetime", formatterParams:{
					outputFormat:"DD MMM",
					invalidPlaceholder:"(invalid date)",
					timezone:"Europe/Amsterdam",
				}},
                {title:"end", field:"endFestivalEvent", formatter:"datetime", formatterParams:{
					outputFormat:"DD MMM",
					invalidPlaceholder:"(invalid date)",
					timezone:"Europe/Amsterdam",
				}},
                {title:"published", field:"publishedOn", formatter:"datetime", formatterParams:{
					outputFormat:"DD-MM-YYYY HH:mm",
					invalidPlaceholder:"(invalid date)",
					timezone:"Europe/Amsterdam",
				}},
                
			],
            initialSort: [
                { column: "year", dir: "desc" }
            ]

		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData(true);
	},
	view: () => {

		return m(Grid, [
            m(header),
			m(Col, {span: 12 }, [
				m('h2','Editions ['+list.length+']'),
				m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					onclick: () => {
						syncData('reload');
					}
				})
            ]),
            m(Col, {span: 12 }, [
				m(ControlGroup, {
					style: 'margin-left: 20px;'
				},[
					m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
					m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
				])
			]),
            m(Col, {span: 12 },
			    m('#grid')
            )
		]);

	}

};