import "./index.scss"
import start from "./lib/start";

// FOR TABULATOR, INCLUDE IN ALL PAGES
import 'moment/locale/nl';
import moment from "moment-timezone";
moment().tz("Europe/Amsterdam");
moment().locale('nl');
window.moment = moment;

// used for displaying progress
window.socketHistory = [];
window.socketOpen = false;

const onDOMContentLoaded = () => {
    start();    
}

document.addEventListener('DOMContentLoaded', onDOMContentLoaded, false);


if (module && module.hot) {
    // module.hot.dispose(()  => {
    //     console.log('hmr dispose')
    // })
    module.hot.accept(() => {
        // console.log('hmr accept');
        setTimeout( () => {
            document.location.reload(true);
        }, 1000)
        
    })
  }