import { m, render, route } from "mithril"
import { Button, Col, Dialog, Grid, Icons } from "construct-ui"
import Tabulator from "tabulator-tables"
import { get } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";
import showBlock from "./renders/showBlock";
import rawObject from "./rawObject";


var grid = false,
filter = "",
rawOpen = false,
list = [],
show = {},
id;

const setGridData = () => {
	console.log('setGridData')
	if(grid){
		grid.replaceData(list).then(function(){
			console.log('setGridData done')

		}).catch(function(err){
			console.error(err);
		});
	}

}

const  syncData = async (cache = true) => {
	if(!list.length || cache === 'reload'){
		list = [];
		show = {};
		console.log('syncData get')
		show = await get('show', {'showId': state.get('showId'), cache: cache });
		console.log('syncData map list')
		Object.keys(show).map( f => {
			list.push({column: f, value: ( Array.isArray(show[f]) ? show[f].join('||') : show[f]) });
		})
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    oninit: () => {
        if(!state.get('showId')){
            route.set("/shows/");
        }
		id = state.get('showId');
		if(!show.id || id !== show.id) list = [];
    },
	oncreate: () => {
		list = [];
		show = {};

		grid = new Tabulator("#grid", {
            persistenceID:"show",
            responsiveLayout:  true,
			persistence: false,
			index: 'column',
			layout: "fitColumns",
			virtualDomBuffer:300,
			columns: [
				{title:"Column", field:"column", width: 200},
				{title:"Value", field:"value"}
			]

		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData();
	},
	view: () => {
		console.log('VIEW', typeof grid, list.length, show.id);
		return [
			m(Dialog, {
				isOpen: rawOpen,
				title: 'RAW FIONA SOURCE VIEW',
				content: m(rawObject, { type: 'show', id: state.get('showId')}),
				hasBackdrop: false,
				portalAttrs: {
					style: {
						width: "80vw"
					}
				},
				onClose: () => {
					rawOpen = false;
				}
			}),
			m(Grid, [
				m(header),
				m(Col, {span: 12 }, [
					show.id ? m('h2.film',`Show: ${show.fullTitle} / ${show.location} / ${moment(show.startOn).format('DD MMM HH:mm')}`) : '',
					m(Button,{
						iconLeft: Icons.ARROW_LEFT,
						label: "shows",
						size: "xs",
						basic: true,
						onclick: () => {
							route.set("/shows/");
						}
					}),
					m(Button,{
						iconLeft: Icons.REFRESH_CW,
						label: "refresh",
						size: "xs",
						basic: true,
						onclick: async () => {
							await syncData('reload');
							return false;
						}
					}),
					m(Button,{
						iconLeft: Icons.EYE,
						label: "raw",
						size: "xs",
						basic: true,
						onclick: () => {
							rawOpen = true;
						}
					})
				]),
				m(Col, {span: 6 },
					m('#grid')
				),
				m(Col, {span: 6 },
					m('.blockday.singleblock', 
						m('.location',
							m('.block', {
								onclick: (e) => {
									console.log(e.currentTarget);
									if(show.filmIds){
										let films = show.filmIds.split("\t");
										if(films.length && films[0]){
											state.set('filmId', films[0]);
											route.set('/film/');
										}
									}
								}
							},
								show.id ? m(showBlock, { key: show.id, show }) : ''
							)
						)
					)
				)

			])
		];

	}

};