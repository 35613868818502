import { Button, Col, TabItem, Tabs } from "construct-ui";
import { m, redraw, route } from "mithril"
import state, { logout } from "../lib/state"

let user, activeTab;

export default {
    oninit: () => {
        user = state.get('user');
        activeTab = route.get();
    },
    onupdate: () => {
        activeTab = route.get();
    },
    oncreate: () => {
        // console.log('header oncreate');
		// moved to start
    },
    view: () => {
        return [
            m(Col, {span: 12}, 
                m('h2', 'FIT: '+user),
            ),
            m(Col, {span: 12, class: 'header' }, [
                m(Button, {
                    style: "position: fixed;top: 0;right: 0;",
                    label: 'Logout',
                    basic: true,
                    size: 'xs',
                    onclick: () => {
                        // route.set("/");
                        logout();
                    }
                }),
                m(Tabs, {
                    align: 'left',
                    fluid: true
                },[
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/shows/',
                        label: 'shows',
                        onclick: () => {
                            route.set('/shows/');
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/films/' || activeTab == '/film/',
                        label: 'films',
                        onclick: () => {
                            route.set('/films/');
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/compositions/' || activeTab == '/composition/',
                        label: 'compositions',
                        onclick: () => {
                            route.set('/compositions/');
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/locations/public/',
                        label: 'locations PUB',
                        onclick: async () => {
                            route.set('/locations/public/');
                            // let locs = await get("locations", {});
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/locations/ind/',
                        label: 'locations IND',
                        onclick: async () => {
                            route.set('/locations/ind/');
                            // let locs = await get("locations", {});
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/lookups/',
                        label: 'lookups',
                        onclick: async () => {
                            route.set('/lookups/');
                            // let locs = await get("locations", {});
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/blocks/',
                        label: 'Blocks',
                        onclick: async () => {
                            route.set('/blocks/');
                            // let locs = await get("locations", {});
                        }
                    }),
                    m(TabItem, {
                        // size: 'xs',
                        // basic: true,
                        // iconLeft: Icons.LIST,
                        active: activeTab == '/downloads/',
                        label: 'Downloads',
                        onclick: async () => {
                            route.set('/downloads/');
                            // let locs = await get("locations", {});
                        }
                    }),
                ]),
                m(Col, m('hr'))
            ])
        ]
    }
}