import nunjucks from "nunjucks";
import moment from "moment";
import QRCode from "qrcode";

var templateRenderer = new nunjucks.Environment(null, {
    autoescape: false
});

templateRenderer.addFilter('tab2char', (str, chars) => {
    if(str){
        return str.split("\t").join(chars || " | "); //(0, count || 5);
    }
    return str;
});

// templateRenderer.addFilter('nl2br', (str, count) => {
//     if(str){
//         return str.replace(/\n/g, "<br/>"); //(0, count || 5);
//     }
//     return str;
// });


// templateRenderer.addFilter('shows', (str, count) => {
//     let shows = str.split("\n");
//     let result = "";
//     shows.map( s => {
//         let show = s.split("\t");
//         let dt = moment(show[0]);
//         if(dt.isValid()){
//             result += dt.format("DD.MM") + " • " + dt.format("HH:mm") + " • "+show[1]+"<br>";
//         }else{
//             result += s;
//         }
//     })
//     return result; //(0, count || 5);
// });

// templateRenderer.addFilter('show', (str, count) => {
//     let result = "";
//     let show = str.split("\t");
//     let dt = moment(show[0]);
//     if(dt.isValid()){
//         result += dt.format("DD.MM") + " • " + dt.format("HH:mm") + " • "+show[1];
//     }else{
//         result += str;
//     }
//     return result;
// });

templateRenderer.addFilter('day', (str, count) => {
    let momentStr = moment(str);
    if(momentStr.isValid()){
        return momentStr.format("DD.MM");
    }else{
        return str;
    }
});

templateRenderer.addFilter('time', (str, count) => {
    let momentStr = moment(str);
    if(momentStr.isValid()){
        return momentStr.format("HH:mm");
    }else{
        return str;
    }
});

// templateRenderer.addFilter('qrcode', async (str, count) => {
//     // console.log('qrCode renderer', arguments)
//     let qrUrl = await QRCode.toDataURL(str);
//     return qrUrl;
// });

// let qrUrlHost = nunjucks.renderString(window.config.qrTemplate, filmData);

if(!window.templateRenderer) window.templateRenderer = templateRenderer;


export const render = (template, data) => {
    try{
        // console.log(data.hasGuests)
        return templateRenderer.renderString(template, data);
    }catch(err){
        return 'TEMPLATE RENDER ERROR: '+err.message
    }
}