import { m, route } from "mithril"
import { Grid, Col, ResponsiveManager, Button, Input, Callout, Dialog, Form, FormGroup, FormLabel, Icons, Icon } from "construct-ui";
import state, { login } from "../lib/state";
import socket from "../lib/socket";
import start from "../lib/start";

let user = '';
let pass = '';

ResponsiveManager.initialize({
    xs: '(max-width: 575.98px)',
    sm: '(min-width: 576px) and (max-width: 767.98px)',
    md: '(min-width: 768px) and (max-width: 991.98px)',
    lg: '(min-width: 992px) and (max-width: 1199.98px)',
    xl: '(min-width: 1200px)'
})

const doLogin = async () => {
    if(user && pass){
        state.set('user', user);
        let ok = await login(user, pass);
        pass = '';
        if(ok){
			if(!window.config) start();
            // socket.start();
            route.set('/films/');
        }else{
            route.set("/?error=401&message=Login failed");
        }
    }
}

const ui = {
    oncreate: () => {
        user = state.get('user');
        // window.s.end();
    },
    view: () => {
        return m(Grid, [
            m(Col, {span: 12}, 
                m('h1', 'Fiona Indesign Toolkit')
            ),
            m(Dialog, {
                isOpen: true,
                closeOnOutsideClick: false,
                closeOnEscapeKey: false,
                hasCloseButton: false,
                class: 'login',
                title: 'LOGIN',
                footer: m(Button, {
                    label: 'login',
                    disabled: !user && !pass,
                    onclick: async () => {
                        await doLogin();
                    }
                }),
                content: [
                    
                    m(Grid, [
                        m(Col, {span: 12}, 
                            route.param('message')
                            ? 
                            m(Callout, {
                                intent: 'primary',
                                icon: Icons.ALERT_TRIANGLE,
                                content: m('.error', route.param('message'))
                            })
                            : '',
                        ),
                        m(Col, {span: 12}, 
                            m(Form, {
                                    justify: 'start'
                                }, [
                                m(FormGroup, [
                                    m(FormLabel, {for: 'username'}, 'Username'),
                                    m(Input, {
                                        id: 'username',
                                        type: 'text',
                                        value: user,
                                        contentRight: m(Icon,{ name: Icons.USER }),
                                        placeholder: 'user',
                                        autofocus: user ? void(0) : true,
                                        oninput: (evt) => {
                                            user = evt.target.value;
                                        }
                                    }),
                                ]),
                                m(FormGroup, [
                                    m(FormLabel, {for: 'password'}, 'Password'),
                                    m(Input, {
                                        id: 'passwprd',
                                        type: 'password',
                                        placeholder: 'password',
                                        value: pass,
                                        autofocus: user ? true : void(0),
                                        contentRight: m(Icon,{ name: Icons.LOCK }),
                                        onkeyup: async (evt) => {
                                            if(evt.keyCode && evt.keyCode === 13){
                                                // console.log('pass enter', pass, evt.keyCode);
                                                await doLogin();
                                            }
                                        },
                                        oninput: (evt) => {
                                            // console.log(evt.currentTarget.value);
                                            pass = evt.currentTarget.value;
                                        }
                                    })
                                ])
                            ]),
                        )
                    ])
                ]
            })

        ]);
    }
}


export default ui;