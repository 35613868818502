import { m, route } from "mithril"
import { Button, Col, ControlGroup, Grid, Icons, Input } from "construct-ui"

import Tabulator from "tabulator-tables"
import { get } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";

var grid = false,
filter = '',
list = [];

const onFilter = (ev) => {
	filter = ev.target.value;
	grid.setFilter('name', "like", ev.target.value);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
		}).catch(function(err){
			console.error(err);
		});
	}
}


const  syncData = async (force = false) => {
	if(!list.length || force == 'reload'){
		grid.clearData();
		list = await get('editiontypes', {cache: force});
		setGridData();
	}else{
		setGridData();
	}

}

export default {
	oncreate: () => {
		grid = new Tabulator("#grid", {
			responsiveLayout:  true,
			persistence: {
                sort: true,
                filter: true
            },
			persistenceID:"editiontypes",
			scrollToRowPosition: "center",
			index: 'id',
			movableColumns:false,
			layout: "fitColumns",
			virtualDomBuffer:300,
			rowClick:function(e, row){
				state.set('editiontypeId', row._row.data.id);
				state.set('editiontypeName', row._row.data.name);
				route.set("/editions/");
			},
			columns: [
				{title:"id", field:"id"},
				{title:"name", field:"name"},
				{title:"description", field:"description"},
				{title:"active edition", field:"activeEdition.description"},
				{title:"published", field:"publishedOn", formatter:"datetime"},
			]

		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData(true);
	},
	view: () => {
		return m(Grid, [
			m(header),
			m(Col, {span: 12 }, [
				m('h2','Editiontypes ['+list.length+']'),
				m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					onclick: () => {
						syncData('reload');
					}
				})
            ]),
            m(Col, {span: 12 }, [
				m(ControlGroup, {
					style: 'margin-left: 20px;'
				},[
					m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
					m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
				])
			]),
            m(Col, {span: 12 },
			    m('#grid')
            )
		]);

	}

};