import { m, route } from "mithril"
import { Button, Col, ControlGroup, Dialog, Grid, Icons, Input, Overlay, TabItem, Tabs } from "construct-ui"
import Tabulator from "tabulator-tables"
import { get } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";
// import filmObject from "./filmObject";
import filmAZ from "./renders/filmAZ";
import rawObject from "./rawObject";

var grid = false,
filter = "",
rawOpen = false,
list = [],
film = {},
id;

let activeTab = 'EN';

const onFilter = (ev) => {
	// console.log('onFilter', ev.target.value);
	filter = ev.target.value;
	grid.setFilter("fullTitle", "like", ev.target.value);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
		}).catch(function(err){
			console.error(err);
		});
	}
}

const  syncData = async (cache = true) => {
	if(!list.length || cache === 'reload'){
		list = [];
		grid.clearData();
        // console.log('refreshing list');
		film = await get('film', {'filmId': state.get('filmId'), cache: cache });
		Object.keys(film).forEach( f => {
			list.push({column: f, value: film[f]});
		})
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    oninit: () => {
		activeTab = (window.config && window.config.filmAZ_EN) ? 'EN' : 'NL'
        if(!state.get('filmId')){
            route.set("/films/");
        }
		id = state.get('filmId');
		if(!film.id || id !== film.id) list = [];
    },
	oncreate: () => {
        // console.log('filmId', state.get('filmId'));
		list = [];
		film = {};

		grid = new Tabulator("#grid", {

            persistenceID:"film",
			headerSort: false,
            responsiveLayout:  true,
			persistence: false,
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer:300,
			columns: [
				{title:"Column", field:"column", width: 200},
				{title:"Value", field:"value"}
			],
            initialSort: [
                // { column: "index", dir: "asc" }
            ]

		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData();
	},
	view: () => {

		return [
			m(Dialog, {
				isOpen: rawOpen,
				title: 'RAW FIONA SOURCE VIEW',
				content: m(rawObject, {type: 'film', id: state.get('filmId')}),
				hasBackdrop: false,
				portalAttrs: {
					style: {
						width: "80vw"
					}
				},
				onClose: () => {
					rawOpen = false;
				}
			}),
			m(Grid, [
				m(header),
				m(Col, {span: 12 }, [
					film.id ? m('h2.film',`Film: ${film.fullTitle}`) : '',
					m(Button,{
						iconLeft: Icons.ARROW_LEFT,
						label: "films",
						size: "xs",
						basic: true,
						onclick: () => {
							route.set('/films/');
						}
					}),
					m(Button,{
						iconLeft: Icons.REFRESH_CW,
						label: "refresh",
						size: "xs",
						basic: true,
						onclick: () => {
							syncData('reload');
						}
					}),
					m(Button,{
						iconLeft: Icons.EYE,
						label: "raw",
						size: "xs",
						basic: true,
						onclick: () => {
							rawOpen = true;
						}
					})
				]),
				m(Col, {span: 12 }, [
					m(ControlGroup, {
						style: 'margin-left: 20px;'
					},[
						m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
						m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
					])
				]),
				m(Col, {span: 6 },
					m('#grid')
				),
				m(Col, {span: 6 },[
					m(Tabs, {
						align: 'left',

					}, [
						window.config && window.config.filmAZ_EN
						? m(TabItem, {
							label: 'AZ EN',
							active: activeTab === 'EN',
							onclick: () => { activeTab = 'EN'}
						})
						: void(0),

						window.config && window.config.filmAZ_NL
						? m(TabItem, {
							label: 'AZ NL',
							active: activeTab === 'NL',
							onclick: () => { activeTab = 'NL'}
						})
						: void(0)
					]),
					m('.tab',
						m(filmAZ, {
							key: film.id,
							film,
							lang: activeTab
						})
					)
				])

			])
		];

	}

};