import { m, redraw, route } from "mithril"
import { Button, ButtonGroup, Classes, Col, Collapse, Dialog, Grid, Icons, List, ListItem } from "construct-ui"
import { cache, get } from "../lib/fiona";
import header from "./header";
import moment from "moment";
import block, { pxPerMinute } from "./renders/block";


var list = [],
locations = [],
edition = {},
loading = false,
festivalStart = moment(),
festivalEnd = moment(),
days = 0,
daysArray = [],
hoursArray = [],
type = 'public',
showDay = 0;


let hourStart = 9;
let hourEnd = 23;

const  syncData = async (force = false) => {
	// if(!list.length || force){
    edition = await get('edition', {cache: 'reload'});

    // PROCESS EDITION DETAILS
    festivalStart = moment(edition.startFestivalEvent);
    festivalEnd = moment(edition.endFestivalEvent);
    days = festivalEnd.diff(festivalStart, 'days') + 1;
    daysArray = [];
    for (let day = 0; day < days; day++) {
        // let dayMoment = festivalStart.clone().add(day, 'day');
        daysArray.push(festivalStart.clone().add(day, 'day'))
        // console.log('add day',day, dayMoment.format("YYYY-MM-DD") );
        
    }
    hoursArray = [];
    for (let h = hourStart; h < hourEnd+1; h++) {
        hoursArray.push( moment("2020-01-01T00:00:00").add(h, 'hour').format('HH:mm') );
    }
    // console.log('hours', hoursArray)

    // GET LOCATIONS
    locations = await get('locations_'+type);
    locations = locations.sort( (a, b) => { 
        return a.sortOrder < b.sortOrder ? -1 : 1;
    });

    // GET CSV of SHOWS
    list = await cache('shows.json')
	// }
    redraw();

}

export default {
	oncreate: () => {
        syncData();
	},
	view: () => {
        
        var day = daysArray[showDay];

        // console.log(day ? day.toISOString() : 0)
		return m(Grid, [
            m(header),
                     
			m(Col, {span: 12 }, [
				m('h2','Block Calendar'),
                m(Button,{
					iconLeft: Icons.ARROW_LEFT,
					label: "shows",
					size: "xs",
					basic: true,
					onclick: () => {
						route.set("/shows/");
					}
				}),
				m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					iconLeftAttrs: {
						class: loading ? 'spinner' : '',
					},
					onclick: () => {
						syncData('reload');
					}
				}),
				// m(Button,{
				// 	iconLeft: Icons.PLAY_CIRCLE,
				// 	label: "generate Shows",
				// 	size: "xs",
				// 	basic: true,
				// 	onclick: async () => {
				// 		overlayOpen = true;
				// 		redraw();
				// 		await generate('shows', {cache: true});
				// 	}
				// }),                
                m(ButtonGroup, [
                    
                    m(Button,{
                        iconLeft: Icons.LIST,
                        label: "PUBLIC",
                        size: "xs",
                        active: type === 'public',
                        intent: type === 'public' ? 'positive' : 'default',
                        iconLeftAttrs: {
                            class: loading ? 'spinner' : '',
                        },
                        onclick: () => {
                            type = 'public';
                            syncData('reload');
                        }
                    }),
                    m(Button,{
                        iconLeft: Icons.LIST,
                        label: "INDUSTRY",
                        size: "xs",
                        active: type === 'ind',
                        intent: type === 'ind' ? 'positive' : 'default',
                        iconLeftAttrs: {
                            class: loading ? 'spinner' : '',
                        },
                        onclick: () => {
                            type = 'ind';
                            syncData('reload');
                        }
                    })
                ])
            ]),
            m(Col, {span: 12 },
                m(ButtonGroup,
                    daysArray.map( ( day, i ) => {
                        return m(Button, {
                            label: day.format('DD MMM'),
                            active: showDay === i,
                            intent: showDay === i ? 'positive' : 'default',
                            onclick: () => {
                                showDay = i;
                            }
                        })
                    })
                ),
            ),
            m(Col, {span: 12, class: 'blockday '+type },
                [
                    m('.timeScale', [
                        hoursArray.map( h => {
                            return m('.hour', {
                                style: `width: ${60*pxPerMinute}px;`
                            }, h);
                        })
                    ]),
                    m('.timeScaleLines', [
                        hoursArray.map( h => {
                            return m('.line', {
                                style: `width: ${60*pxPerMinute}px;`
                            }, '');
                        })
                    ]),
                    locations.map( location => {
                        // filter shows by location
                        let shows = list.filter(show => {
							if(show.locationId === location.id){
								if(day.isSame(moment(show.startOn), 'day')){
									console.log('Location && dayMatch', show);
								}
							}
							
                            return show.locationId === location.id 
                                && day.isSame(moment(show.startOn), 'day')
                                && (
                                    (
                                        !show.type || !window.config || !window.config.showTypes
                                    ) || (
                                        show.type && window.config 
                                        && window.config.showTypes 
                                        && window.config.showTypes[type] && window.config.showTypes[type].indexOf(show.type) > -1
                                    ) || (
                                        show.type === type
                                    )
                                && !show.donotshow 


                                )
                                // && ( 
                                //     ( show.type === type )
                                //     || 
                                //     ( type === 'ind' && show.comp_type === 'comp-ip')
                                // )
                                // && !show.donotshow 
                        });
                                
                        if(shows.length){
                            // sort shows by startOn
                            shows = shows.sort( (a,b) => {
                                return moment(a.startOn).isBefore(moment(b.startOn)) ? -1 : 1;
                            });
                            // return location row
                            return m('.location', [
                                m('.location_title', location.name),
                                // return show block
                                shows.map( show => {
                                    return m(block, { key: show.id, show }
                                    );
                                })
                            ])
                        }else{
                            return void(0);
                        }
        
                    })
                ]
            )
		]);

	}

};