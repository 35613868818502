import { m, route, trust } from "mithril"
import { render } from "../../lib/nunjuckEngine";
import state from "../../lib/state";

const comma = { view: () => m('span.comma', ',') };
var scaleWidth = window.innerWidth - 20; //1000;
const blocksStart = 100;
const hoursPerDay = 16;
var pxPerMinute = scaleWidth / (hoursPerDay*60); // pixels per minute
const dayStart = (9*60);


const calcLeft = (show) => {
    let start = moment(show.startOn);
    let minutes = (start.hours()*60)+start.minutes();
    return blocksStart+(minutes-dayStart)*pxPerMinute;
}

const calcWidth = (left, duration) => {
    let width = duration*pxPerMinute;
    if( left+width > scaleWidth ) width = scaleWidth-left;
    if(width < 60*pxPerMinute) width = 60*pxPerMinute;
    return width;
}

const getPos = (show) => {
    let left = calcLeft(show);
    let width = calcWidth(left, show.totalDuration);
    return { left, width };
}
export {
    scaleWidth,
    pxPerMinute,
    blocksStart,
    dayStart
}
export default {
    
    view: ({attrs: {show, lang = 'EN'}}) => {
        scaleWidth = window.innerWidth;
        pxPerMinute = scaleWidth / (hoursPerDay*60);    
        let pos = getPos(show);
         return  m('.block', {
                style: `left: ${pos.left}px;width: ${pos.width}px;`,
                onclick: () => {
                    // console.log(show.id);
                    state.set('showId', show.id);
                    route.set('/show/');                    
                }
            }, 
            trust(render(window.config[`showBlock`], show))
        );
    }
};