import { m, redraw, route } from "mithril"
import { Button, Col, ControlGroup, Grid, Icons, Input } from "construct-ui"
import Tabulator from "tabulator-tables"
import { cache } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";

/** @type {Tabulator.TabulatorFull} */
var grid = false,
filter = "",
list = [],
loading = false


const onFilter = (ev) => {
	// console.log('onFilter', ev.target.value);
	filter = ev.target.value;
	grid.setFilter([[
		{field: "fullTitle", type: "like", value: filter},
		{field: "comp_title", type: "like", value: filter},
		{field: "id", type: "like", value: filter},
		{field: "showIds", type: "like", value: filter}
	]]);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
			loading = false;
			redraw();	
		}).catch(function(err){
			console.error(err);
			loading = false;
			redraw();
		});
	}else{
		loading = false;
	}
	
}

const  syncData = async (force = false) => {
	if(!list || !list.length || force){
		grid.clearData();
		loading = true;
		redraw();
		list = await cache('films.json')
		setGridData();
	}else{
		setGridData();
	}

}

export default {
	oncreate: () => {
		grid = new Tabulator("#grid", {

            persistenceID:"films",

            responsiveLayout:  true,
			persistence: {
                sort: true,
                filter: true
            },
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer: 100,
			height: "500px",
			rowClick:function(e, row){
				state.set('filmId', row._row.data.id);
				state.set('filmName', row._row.data.fullPreferredTitle);
				route.set("/film/");
			},
			columns: [
				{title:"id", field:"id"},
                {title:"sortedTitle", field:"sortedTitle"},
				{title:"fullTitle", field:"fullTitle"},
				{title:"title", field:"title"},
				{title:"secondTitle", field:"secondTitle"},
				{title:"directors", field:"directors"},
				{title:"premiere", field:"premiere"},
				{title:"tags", field:"tags"},
				{title:"sectionNames", field:"sectionNames"},
				// {title:"isDocLab", field:"isDocLab"},
				{title:"shows", field:"shows_NL", formatter: cell => {
					let shows = cell.getValue();
					if(shows){
						return shows.split("\n").length || 0;
					}else{
						return 0;
					}
				}},
                {title: "film_error", field: "film_error"},
				{title: "show_error", field: "show_error"},
				{title: "comp_error", field: "comp_error"},
			],
            initialSort: [
                { column: "sortedTitle", dir: "asc" }
            ]

		});
		var filters = grid.getFilters();
		if(window.config && window.config.filmColumns){
			grid.setColumns(window.config.filmColumns);
		}
		
		if(filters.length){
			if(Array.isArray(filters[0]) ){
				filter = filters[0][0].value;
			}else{
				filter = filters[0].value;
			}
		}
		syncData(true);
	},
	view: () => {
		// serviceStatus = serviceHistory['filmsAZ'] ? serviceHistory['filmsAZ'] : {};

		return m(Grid, [
            m(header),
			// m(Dialog, {
			// 	isOpen: overlayOpen,
			// 	onClose:  () => { overlayOpen = false },
			// 	hasCloseButton: true,
			// 	title: 'films AZ generator',
			// 	content: m('.generator',[
			// 		m('.progress', m('.bar', { style: `width: ${serviceStatus.progress ? serviceStatus.progress : 0}%;` }) ),
			// 		m(List,
			// 			Object.keys(serviceStatus).map( s => {
			// 				return m(ListItem, {contentLeft: s, label: serviceStatus[s].toString() } );
			// 			})
			// 		)
			// 	])
			// }),
			m(Col, {span: 12 }, [
				m('h2','Films ['+(list ? list.length : 0)+']'),
				m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					iconLeftAttrs: {
						class: loading ? 'spinner' : '',
					},
					basic: true,
					onclick: () => {
						syncData('reload');
					}
				}),
				// m(Button,{
				// 	iconLeft: Icons.PLAY_CIRCLE,
				// 	label: "re-generate AZ",
				// 	size: "xs",
				// 	basic: true,
				// 	onclick: async () => {
				// 		overlayOpen = true;
				// 		redraw();
				// 		await generate('filmsAZ', { cache: 'raw'});
				// 	}
				// }),
				// m(Button,{
				// 	iconLeft: Icons.STOP_CIRCLE,
				// 	label: "stop AZ",
				// 	size: "xs",
				// 	basic: true,
				// 	onclick: async () => {
				// 		await generate('filmsAZ', {stop: true, cache: true});
				// 	}
				// })
            ]),
            m(Col, {span: 12 }, [
				m(ControlGroup, {
					style: 'margin-left: 20px;'
				},[
					m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
					m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter ? false : true})
				])
			]),
            m(Col, {span: 12 },
			    m('#grid')
            )
		]);

	}

};