import { Button, Card, Col, Dialog, Grid, Icons, List, ListItem, Spinner, Table } from "construct-ui";
import { CLOUD_LIGHTNING, DOWNLOAD_CLOUD, REFRESH_CCW, REFRESH_CW, SETTINGS, UPLOAD_CLOUD } from "construct-ui/lib/esm/components/icon/generated/IconNames";
import { m, redraw, request } from "mithril"
import moment from "moment";
import { generate, get, post } from "../lib/fiona";
import { serviceHistory } from "../lib/socket";
import state from "../lib/state";
import header from "./header";

var list = [];
var dialogOpen = false;
var serviceStatus = {};
var service = "";
const syncData = async (force = false) => {
    list = await get('downloads', { cache: force });
    redraw();
}

// const downloadFile = (file) => {
//     //'/customer/' + file.file + "?token=" + state.get('token');
//     let fileExt = file.split('.')
//     fileExt = fileExt[fileExt.length-1];

//     if(fileExt === 'csv'){

//     }
//     let fileUrl = '/customer/' + file + "?token=" + state.get('token');
//     request({
//         url: fileUrl,
//         method: 'GET',
//         responseType: 'blob', // important
//       }).then((response) => {
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', file);
//         link.setAttribute('id', 'downloadlink '+file);
//         document.body.appendChild(link);
//         link.click();
//         setTimeout(() => {
//             console.log('clean download link?');
//             // document.body.removeChild(link);
//             // delete link; // clean up
//         }, 0)
//       });

// }

export default {
    oninit: () => {
        syncData('reload');
        serviceStatus = serviceHistory[service] ? serviceHistory[service] : {};

    },
    view: () => {
        serviceStatus = serviceHistory[service] ? serviceHistory[service] : {};

        return m(Grid, [
            m(header),
            m(Dialog, {
                isOpen: dialogOpen,
                onClose: () => {
                    dialogOpen = false;
                    syncData('reload');
                },
                hasCloseButton: true,
                title: service + ' Generator',
                content: m('.generator', [
                    // m('.progress', m('.bar', { style: `width: ${serviceStatus.progress ? serviceStatus.progress : 0}%;` })),
                    serviceStatus['running'] ? m(Spinner, { active: true, message: 'running'}) : '',

                    m(List,
                        Object.keys(serviceStatus).map(s => {
                            return m(ListItem, { key: s, contentLeft: s, label: serviceStatus[s].toString() });
                        })
                    )
                ])
            }),
            // m('#downloads', [
            // m('a#downloadAnchorElem', { style: "display: none;" }),
            m(Col, { span: 12 },
                m(Grid, { class: 'downloads', gutter: 20 },
                    list.map(d => {
                        let hideReload = false;
                        if( !['films', 'shows', 'locations', 'compositions'].includes(d.name) ) hideReload = true;
                        return m(Col, { span: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6 } },
                            m(Card, {
                                interactive: false,
                                elevation: 4,
                                fluid: true
                            }, [
                                m('h3', d.name),
                                Object.keys(d.files).map( f => {
                                    let file = d.files[f];
                                    return m(Grid, { class: 'fileDetails' }, [
                                        m(Col, { span: 3, style: "word-wrap: break-word;" }, m('file', file.file)),
                                        m(Col, { span: 3 },
                                            m('a', { 
                                                // size: 'xs', basic: true, 
                                                // iconLeft: Icons.DOWNLOAD_CLOUD, 
                                                // label: 'download', 
                                                href: '/customer/' + file.file, // + "?token=" + state.get('token'),
                                                // onclick: () => {
                                                //     // downloadFile(file.file);
                                                //     window.open('/customer/' + file.file + "?token=" + state.get('token'), 'fitdownload');
                                                // } 
                                            }, 'download')
                                        ),
                                        m(Col, { span: 3 }, m('.modified', moment(file.modified).format("DD MMM HH:mm")) ),
                                        m(Col, { span: 3 }, m('.size', file.size) )
                                    ])
                                }),
                                m(Button, {
                                    label: 're-generate',
                                    iconLeft: REFRESH_CCW,
                                    rounded: true,
                                    onclick: async () => {
										let ok = confirm("\rAre you sure? This is only required if the code logic has changed and used by Jeroen only...\n\r\nIf you have done a RELOAD DATA, this is already done and not necessary!\n\rThis is a heavy load on the server.");
                                        if(ok){
											dialogOpen = true;
											redraw();
											service = d.name;
											await generate(service, { cache: 'raw' });
										}
                                    }
                                }),
                                m(Button, {
                                    label: 'reload Fiona data',
                                    iconLeft: CLOUD_LIGHTNING,
                                    rounded: true,
                                    style: hideReload ? "display:none;" : "",
                                    onclick: async () => {
                                        let ok = confirm("\rAre you sure?\n\rThis is only necessary if data in Fiona has changed and republished. \n\rIt is a heavy load on the server and on Fiona.");
                                        if(ok){
                                            dialogOpen = true;
                                            redraw();
                                            service = d.name;
                                            await generate(service, { cache: 'reload' });
                                        }
                                    }
                                })                                

                            ])

                        )

                    })
                )
            )
        ]);

    }
};