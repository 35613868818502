import { m, route } from "mithril"
import { Button, Col, ControlGroup, Grid, Icons, Input } from "construct-ui"
import Tabulator from "tabulator-tables"
import { cache, get } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";

var grid = false,
filter = "",
list = [];

const onFilter = (ev) => {
	// console.log('onFilter', ev.target.value);
	filter = ev.target.value;
	grid.setFilter("fullTitle", "like", ev.target.value);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
		}).catch(function(err){
			console.error(err);
		});
	}
}

const  syncData = async (force = false) => {
	if(!list.length || force){
		grid.clearData();
		// list = await get('compositions', { cache: force});
		list = await cache('compositions.json');
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    oninit: () => {
        // if(!state.get('editionId')){
        //     route.set("/editions/");
        // }
    },
	oncreate: () => {
        // console.log('editionId', state.get('editionId'));

		grid = new Tabulator("#grid", {

            persistenceID:"compositions",

            responsiveLayout:  true,
			persistence: {
                sort: true,
                filter: true
            },
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer: 100,
			height: "500px",
			rowClick:function(e, row){
				state.set('compositionId', row._row.data.id);
				state.set('compositionName', row._row.data.fullTitle);

				route.set("/composition/");
			},
			columns: [
				{title:"id", field:"id"},
				{title:"fullTitle", field:"fullTitle"},
                {title: "sortedTitle", field: "sortedTitle"},
				{title: "type", field: "type"},
				{title: "sectionNames", field: "sectionNames"},
				{title:"shows", field:"shows_NL", formatter: cell => {
					let shows = cell.getValue();
					if(shows){
						return shows.split("\n").length || 0;
					}else{
						return 0;
					}
				}},
				// {title: "isDoubleBill", field: "isDoubleBill"},
				// {title: "isDocLab", field: "isDocLab"},
				{title: "film1Title", field: "film1Title"},
				{title: "film2Title", field: "film2Title"},
				// {title: "hide AZMagazine", field: "donotshow_AZMagazine"},
				// {title: "hide Magazine", field: "donotshow_Magazine"},
				// {title: "hide Guide", field: "donotshow_Guide"}
			],
            initialSort: [
                { column: "sortedTitle", dir: "asc" }
            ]

		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData(true);
	},
	view: () => {

		return m(Grid, [
            m(header),
			m(Col, {span: 12 }, [
				m('h2','compositions ['+list.length+']'),
				m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					onclick: () => {
						syncData('reload');
					}
				})
            ]),
            m(Col, {span: 12 }, [
				m(ControlGroup, {
					style: 'margin-left: 20px;'
				},[
					m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
					m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
				])
			]),
            m(Col, {span: 12 },
			    m('#grid')
            )
		]);

	}

};