import socket from "./socket";
import routes from "./routes";
import state from "./state";
import { request } from "mithril";
import { get } from "./fiona";
// var remote;

const start = async () => {

	let token = state.get('token');

	// VERIFY TOKEN
	let tokenOK = await get("check/")
	// console.log('tokenOK', tokenOK)

	if(token && tokenOK && tokenOK.auth && !window.config){

		var jsId = 'customerJS';
		if (!document.getElementById(jsId)){
			var script = document.createElement('script');
			script.id = jsId;
			script.onload = function () {
				//do stuff with the script
				console.log('config loaded');
				routes();
				// redraw();
			};
			script.src = '/customer/config.js';
			script.addEventListener('error', function(err){
				// Didn't load
				console.log('load customer error', err)
			}, true);
			document.head.appendChild(script); //or something of the likes
		}

		var cssId = 'customerCSS';  // you could encode the css path itself to generate id..
		if (!document.getElementById(cssId)){
			var head  = document.getElementsByTagName('head')[0];
			var link  = document.createElement('link');
			link.id   = cssId;
			link.rel  = 'stylesheet';
			link.type = 'text/css';
			link.href = '/customer/styles.css';
			link.media = 'all';
			link.onload = function () {
				//do stuff with the script
				console.log('css loaded');
				// redraw();
			};
			head.appendChild(link);
		}
	}else{
		routes();
	}

    // routes();
    window.s = socket.start();
}

export default start;