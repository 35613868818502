import { m, redraw, trust } from "mithril"
import state from "../../lib/state";
import { render } from "../../lib/nunjuckEngine";


let html = '<p>Loading</p>';
let compiledLang = '';

const generateHtml = async (show, lang) => {
    // console.log('do we generateHtml?')
    if(show && show.hasOwnProperty('id') && window.config && lang && compiledLang !== lang){

        // console.log('generateHtml', lang, show.id);
        compiledLang = lang;

        let showData = Object.assign({}, show);
        showData.token = state.get('token');

        html  = render(window.config[`showBlock`], showData);

        redraw();

    }
}

export default {
    oninit: async () => {
        compiledLang = '';
        html = false;
    },
    oncreate: ({attrs: {show = false, lang = 'EN'}}) => {
        // console.log('oncreate', show, lang);
        html = false;
        compiledLang = '';
        generateHtml(show, lang);

    },
    // onbeforeupdate: ({attrs: {show = false, lang = 'EN'}}) => {
    //     // console.log('onbeforeupdate', show, lang);
    //     // return false;
    //     // if(!show || !window.config) return false;
    //     // generateHtml(show, lang);
    //     // return true;
    // },
    view: ({attrs: {show = false, lang = 'EN'}}) => {
        // console.log('view');
        generateHtml(show, lang);
        return m('#show', {style: { backgroundColor: '#fff'}}, [
            html  ? trust(html) : ''
        ]);
    }
};