import { m, redraw, route } from "mithril"
import { Button, Col, ControlGroup, Dialog, Grid, Icons, Input } from "construct-ui"
import Tabulator from "tabulator-tables"
import { get } from "../lib/fiona";
import header from "./header";
import lookupObject from "./lookupObject";

var grid = false,
filter = "",
list = [],
rawOpen = false,
lookupId = false, lookupName = '', lookupKey = '';

const onFilter = (ev) => {
	filter = ev.target.value;
	grid.setFilter("name", "like", ev.target.value);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
		}).catch(function(err){
			console.error(err);
		});
	}
}

const  syncData = async (force = false) => {
	if(!list.length || force == 'reload'){
		grid.clearData();

        // console.log('refreshing list');
		list = await get('lookups', {cache: force});
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    oninit: () => {
    },
	oncreate: () => {

		grid = new Tabulator("#grid", {

            persistenceID:"lookups",

            responsiveLayout:  true,
			persistence: {
                sort: true,
                filter: true
            },
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer:300,
			rowClick:function(e, row){
				lookupId = row._row.data.id;
				lookupKey = row._row.data.key;
				lookupName = row._row.data.name;
				rawOpen = true;
				redraw();
			},
			// rowClick:function(e, row){
			// 	state.set('editionId', row._row.data.id);
			// 	state.set('editionName', row._row.data.name);

			// 	route.set("/films/");
			// },
			columns: [
				{title:"id", field:"id"},
				{title:"key", field:"key"},
				{title:"name", field:"name"},
                // {title:"published", field:"publishedOn", formatter:"datetime", formatterParams:{
				// 	outputFormat:"DD-MM-YYYY HH:mm",
				// 	invalidPlaceholder:"(invalid date)",
				// 	timezone:"Europe/Amsterdam",
				// }},
                
    		]
		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData(true);
	},
	view: () => {

		return m(Grid, [
            m(header),
			m(Dialog, {
				isOpen: rawOpen && lookupId,
				content: m(lookupObject, {type: 'lookup', id: lookupId, title: `${lookupName} [${lookupKey}]` }),
				hasBackdrop: false,
				portalAttrs: {
					style: {
						width: "80vw"
					}
				},
				onClose: () => {
					rawOpen = false;
					lookupId = '';
				}
			}),			
			m(Col, {span: 12 }, [
				m('h2','lookups ['+list.length+']'),
                m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					onclick: () => {
						syncData('reload');
					}
				})
            ]),
            m(Col, {span: 12 }, [
				m(ControlGroup, {
					style: 'margin-left: 20px;'
				},[
					m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
					m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
				])
			]),
            m(Col, {span: 12 },
			    m('#grid')
            )
		]);

	}

};