import { request, route } from "mithril";
import state from "./state";

const get = async ( endpoint, data ) => {
    try{
        let token = state.get('token');
        if(!token){
            route.set("/?error=401");
        }else{
            let result =  await request({
                url: '/api/'+endpoint,
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`
                },
                params: data
            });
            return result;
        }
        
    }catch(err){
        console.log('get error', err.code);
        if(err.code && err.code === 401){
            route.set("/?error=401");
            return [];
        }else{
            // route.set("/?error=500&msg="+err.message);
            console.log(err.code, err.response);
            return [];
        }
    }
}

// const download = async ( file ) => {
//     try{
//         let token = state.get('token');
//         if(!token){
//             route.set("/?error=401");
//         }else{
//             window.open('/customer/'+file+"?token="+token, 'fitdownload');
//         }
        
//     }catch(err){
//         console.log('get error', err.code);
//         if(err.code && err.code === 401){
//             route.set("/?error=401");
//             return [];
//         }else{
//             // route.set("/?error=500&msg="+err.message);
//             console.log(err.code, err.response);
//             return [];
//         }
//     }
// }

const post = async ( endpoint, data ) => {
    try{
        let token = state.get('token');
        if(!token){
            route.set("/?error=401");
        }else{
            return await request({
                url: '/api/'+endpoint,
                method: 'POST',
                headers: {
                    'authorization': `Bearer ${token}`
                },
                body: data
            })
        }
    }catch(err){
        console.log('get error', err.code);
        if(err.code && err.code === 401){
            route.set("/?error=401");
        }else{
            // route.set("/?error=500&msg="+err.message);
            console.log(err.code, err.response)
        }
        return;
    }
}

const cache = async ( file ) => {
    try{
        let token = state.get('token');
        if(!token){
            route.set("/?error=401");
        }else{
            let result =  await request({
                url: '/customer/'+file,
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
            return result;
        }
        
    }catch(err){
        console.log('get error', err.code);
        if(err.code && err.code === 401){
            route.set("/?error=401");
            return [];
        }else{
            // route.set("/?error=500&msg="+err.message);
            console.log(err.code, err.response);
            return [];
        }
    }
}

const config = async ( ) => {
    try{
        let token = state.get('token');
        if(!token){
            route.set("/?error=401");
        }else{
            let result =  await request({
                url: '/customer/config.js',
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
            return result;
        }
        
    }catch(err){
        console.log('get error', err.code);
        if(err.code && err.code === 401){
            route.set("/?error=401");
            return [];
        }else{
            // route.set("/?error=500&msg="+err.message);
            console.log(err.code, err.response);
            return [];
        }
    }
}

const generate = async ( endpoint, data ) => {
    try{
        let token = state.get('token');
        if(!token){
            route.set("/?error=401");
        }else{
            let result =  await request({
                url: '/generate/'+endpoint,
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`
                },
                params: data
            });
            return result;
        }
        
    }catch(err){
        console.log('get error', err.code);
        if(err.code && err.code === 401){
            route.set("/?error=401");
            return [];
        }else{
            // route.set("/?error=500&msg="+err.message);
            console.log(err.code, err.response);
            return [];
        }
    }
}

export {
    get,
    post,
    generate,
    // download,
    config,
    cache
}
