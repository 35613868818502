import { m, redraw, route } from "mithril"
import { Button, Col, ControlGroup, Grid, Icons, Input } from "construct-ui"
import Tabulator from "tabulator-tables"
import { cache } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";
import moment from "moment";

var grid = false,
columnsReady = false,
filter = "",
list = [],
loading = false;



const onFilter = (ev) => {
	// console.log('onFilter', ev.target.value);
	filter = ev.target.value;
	grid.setFilter([[
		{field: "fullTitle", type: "like", value: filter},
		{field: "id", type: "like", value: filter},
		{field: "compositionName", type: "like", value: filter}

	]]);
}

const resetFilter = (ev) => {
	grid.clearFilter();
	filter = "";
}

const setGridData = () => {
	if(grid){
		// if(!columnsReady){
		// 	Object.keys(list[0]).map( key => {
		// 		grid.addColumn({
		// 			title: key, field: key, visible: true
		// 		})
		// 	})
		// 	columnsReady = true;
		// 	// grid.setGroupBy(["startOn", "location"])

		// }
		grid.replaceData(list).then(function(){
            loading = false;
            redraw();
		}).catch(function(err){
            loading = false;
            redraw();
			console.error(err);
		});
	}
}

const  syncData = async (force = false) => {
    loading = true;
    redraw();
	if(!list.length || force){
		grid.clearData();
        // console.log('refreshing list');
		// list = await get('editionshows', {cache: force ? 'reload' : true});
		list = await cache('shows.json')
		setGridData();
	}else{
		setGridData();
	}

}

export default {
	oncreate: () => {
		grid = new Tabulator("#grid", {

            persistenceID:"shows",

            responsiveLayout:  true,
			persistence: {
                sort: true,
                filter: true
            },
			// groupToggleElement: "header",
			// groupHeader: function(value, count, data, group){
			// 	return value + "<span style='color:#d00; margin-left:10px;'>(" + count + " item)</span>";
			// },
			// groupHeaderHtmlOutput: function(value, count, data, group){
			// 	return value + "<span style='color:#d00; margin-left:10px;'></span>";
			// },
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer: 100,
            height: "500px",
			rowClick:function(e, row){
				state.set('showId', row._row.data.id);
				route.set("/show/");
			},
			columns: [
				{title:"id", field:"id"},
				{title:"fullTitle", field:"fullTitle"},
				{title:"compositionName", field:"compositionName"},
                {title: "sectionNames", field: "sectionNames"},
				{title: "isPremiere", field: "isPremiere"},
				{title: "location", field: "location"},
                // {title: "startOn", field: "startOn", formatter: "datetime", formatterParams:{
				// 	// inputFormat:"YYYY-MM-DD HH:ii",
				// 	outputFormat:"DD-MM-YYYY HH:mm",
				// 	invalidPlaceholder:"(invalid date)",
				// 	timezone:"Europe/Amsterdam",
				// }},
				{title: 'startOn', field: 'startOn', formatter: (cell) => {
					let value = cell.getValue();
					value = moment(value);
					if(value.isValid()){
						return value.format("DD-MM-YYYY HH:mm");
					}else{
						return '-';
					}
				}},
				{title: "audience", field: "audience"},
				// {title: "guests", field: "guests"},
				{title: "type", field: "type"},
				// {title:"films", field:"filmIds", formatter: cell => {
				// 	let value = cell.getValue();

				// 	let count = 0;
				// 	if(value) count = value.split("\t").length;
				// 	return count;
				// }},
				// {title: "comp type", field: "compositionType"},
				// {title: "Double bill", field: "isDoubleBill"},
				{title: "film_error", field: "film_error"},
				{title: "show_error", field: "show_error"},
				{title: "comp_error", field: "comp_error"},
			],
            initialSort: [
                { column: "startOn", dir: "asc" }
            ]

		});
		var filters = grid.getFilters();
		if(filters.length){
			if(Array.isArray(filters[0]) ){
				filter = filters[0][0].value;
			}else{
				filter = filters[0].value;
			}
		}
		syncData();
	},
	view: () => {
		return m(Grid, [
            m(header),
			m(Col, {span: 12 }, [
				m('h2','Shows ['+list.length+']'),
				m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					iconLeftAttrs: {
						class: loading ? 'spinner' : '',
					},
					onclick: () => {
						syncData('reload');
					}
				}),
				// m(Button,{
				// 	iconLeft: Icons.PLAY_CIRCLE,
				// 	label: "generate Shows",
				// 	size: "xs",
				// 	basic: true,
				// 	onclick: async () => {
				// 		overlayOpen = true;
				// 		redraw();
				// 		await generate('shows', {cache: 'raw'});
				// 	}
				// }),
				// m(Button,{
				// 	iconLeft: Icons.STOP_CIRCLE,
				// 	label: "stop generator",
				// 	size: "xs",
				// 	basic: true,
				// 	onclick: async () => {
				// 		await generate('shows', {stop: true, cache: true});
				// 	}
				// }),
				m(Button,{
					iconLeft: Icons.ALIGN_RIGHT,
					iconRight: Icons.ARROW_RIGHT,
					label: "Blocks",
					basic: true,
					size: "xs",
					onclick: async () => {
						route.set("/blocks/");
					}
				})
            ]),
            m(Col, {span: 12 }, [
                m(ControlGroup, {
					    style: 'margin-left: 20px;'
				},[
                        m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
                        m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
				])
			]),
            m(Col, {span: 12 },
			    m('#grid')
            )
		]);

	}

};