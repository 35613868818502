import { m } from "mithril";
import JSONViewer from '../lib/jsonViewer';
import { get } from "../lib/fiona";
import { Button, Checkbox, Icons } from "construct-ui";


var objViewer = false,
obj = {},objShow = [],
objType, objId;

var fullObj = false;

const setGridData = () => {
	if(objViewer){
        objViewer.showJSON(objShow, -1, 2);
	}
}

const  syncData = async (force = false) => {
	if(!obj || force){
        // console.log('refreshing obj', objType);
        let reqData = {};
        reqData[objType+'Id'] = objId;
        reqData.raw = true;
		reqData.cache = force;
		objShow = [];
		obj = await get(objType, reqData);
	}
	if(fullObj){
		objShow = obj;
	}else{
		objShow = [];
		for( const o of obj){
			objShow.push({
				key: o.key,
				abbreviation: o.translations.find( t => t.language == 'en').abbreviation,
				name: o.translations.find( t => t.language == 'en').text
			});
		}
	}
	setGridData();
	

}

export default {
    // oninit: () => {},
	oncreate: ({ attrs: { type, id }}) => {
        // console.log('raw', type, id);
        objType = type;
        objId = id;

        objViewer = new JSONViewer();
        document.getElementById("jsonViewer").appendChild(objViewer.getContainer());
		syncData(true);
	},
	view: ({ attrs: { title } }) => {
		return [
			m('h3', title),
			m(Button,{
				iconLeft: Icons.REFRESH_CW,
				label: "refresh",
				size: "xs",
				basic: true,
				onclick: () => {
					syncData('reload');
				}
			}),
			m(Button,{
				label: "Show full object",
				size: "xs",
				basic: true,
				iconLeft: fullObj ? Icons.CHECK_CIRCLE : Icons.CIRCLE,
				checked: fullObj,
				onclick: () => {
					fullObj = !fullObj;
					syncData(false)
				}
			}),
			m('#jsonViewer')
		];
	}

};