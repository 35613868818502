import { redraw, request, route } from "mithril";
import { end } from "./socket";

const state = {

    // defaults
    connected: false,

    set: (key, val) => {
        state[key] = val;
        localStorage.setItem(key, val)
        redraw();
    },
    get: (key) => {
        if(!state.hasOwnProperty(key)){
            state[key] = localStorage.getItem(key);
        }
        return state[key] || '';
    }


}

const logout = async () => {

    state.set('token', '');

    document.cookie = 'token= ;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    let customJS = document.getElementById('customerJS');
    let customCSS = document.getElementById('customerCSS');
    if(customJS) customJS.parentElement.removeChild(customJS);
    if(customCSS) customCSS.parentElement.removeChild(customCSS);
    window.config = false;

    end();
    route.set('/');
}

const login = async (user, pass) => {
    try{
        let result = await request({
            url: "/auth/",
            method: "POST",
            body: { user, pass }
        });
        // console.log('login', result);
        state.set('token', result.token);
        document.cookie = `token=${state.get('token')}; sameSite=Strict; secure; path=/`;
        return true;
    }catch(err){
        console.log('login error', err);
        return false;
        // route.set("/?error=401&message=Login failed");
    }
}

export default state;
export {
    login,
    logout
}
