// import EventEmitter from "../../node_modules/eventemitter3/index";
import ReconnectingWebSocket from 'reconnecting-websocket';
import state from "./state";
import { redraw, route } from "mithril";
// import state from "./state";
let serviceHistory = {};
let secureSocket = (document.location.protocol === 'https:');
let wsHost = (secureSocket ? 'wss://' : 'ws://')+document.location.host+'/ws';

// const EE = new EventEmitter;
const ws = new ReconnectingWebSocket(wsHost, null, { startClosed: true } );

ws.onerror = (err) => {
    // console.log(err);
    // console.log('SCKT', err);
};

ws.onclose = (evt) => {
    // console.log(`SOCKET closed`);
    window.socketOpen = false;
    // EE.emit('close', evt);
    redraw();
};

ws.onopen = (evt) => {
    window.socketOpen = true;
    // EE.emit('open', evt);
    redraw();
    // console.log('SOCKET opened');
};    

ws.onmessage = (evt) => {
    // console.log(evt);
    // EE.emit('message', evt.data);
    let msg = JSON.parse(evt.data);
    if(msg.service){
        if(!serviceHistory[msg.service]) serviceHistory[msg.service] = {};
        serviceHistory[msg.service] = Object.assign({}, serviceHistory[msg.service], msg);
        // console.log(serviceHistory[msg.service]);
    }
    redraw();
    // console.debug('RECEIVED:', evt.data);
};    


const start = () => {
    let token = state.get('token');
    if(token){
        // console.log('set token cookie', token);
        document.cookie = `token=${token}; sameSite=Strict; path=/;${secureSocket ? 'secure;': ''}`;
    }else{
        route.set('/', {error: 401})
    }
    ws.reconnect();
    return socket;
    // return EE;
}

const send = (cmd) => {
    ws.send(JSON.stringify(cmd));
    // console.debug('SEND:', cmd);
}

const end = () => {
    ws.close();
}

const socket = {
    start,
    send,
    end,
    serviceHistory
}

export default socket;
export {
    start,
    send,
    end,
    serviceHistory
};