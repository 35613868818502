import { m, redraw, trust } from "mithril"
import state from "../../lib/state";
import { render } from "../../lib/nunjuckEngine";



let html = '';
let compiledLang = '';

const generateHtml = async (film, lang) => {
    // console.log('do we generateHtml?')
    if(film && film.hasOwnProperty('id') && window.config && lang && compiledLang !== lang){

		if(!window.config[`filmAZ_${lang}`]) {
			html = '';
			return '';
		}
        // console.log('generateHtml', lang, film.id);
        compiledLang = lang;

        let filmData = Object.assign({}, film);
        filmData.token = state.get('token');

        html  = render(window.config[`filmAZ_${lang}`], filmData);

        redraw();

    }
}

// const generateHtml = async (film, lang) => {

//     if(film && film.hasOwnProperty('id') && window.config && lang && compiledLang !== lang){

//         console.log('process', lang, film.id);
//         compiledLang = lang;

//         let filmData = Object.assign({}, film);

//         let qrUrlHost = nunjucks.renderString(window.config.qrTemplate, filmData);
//         let qrUrl = await QRCode.toDataURL(qrUrlHost);

//         filmData.qrUrl = qrUrl;
//         filmData.token = state.get('token');
//         html  = templateRenderer.renderString(window.config[`filmAZ_${lang}`], filmData);
//         redraw();

//     }
// }

export default {
    oninit: async () => {
        compiledLang = '';
        html = false;
    },
    oncreate: ({attrs: {film = false, lang = 'EN'}}) => {
        html = false;
        generateHtml(film, lang);

    },
    onbeforeupdate: ({attrs: {film = false, lang = 'EN'}}) => {
        if(!film && !window.config) return false;
        generateHtml(film, lang);
        return true;
    },
    view: () => {
        // generateHtml(film, lang);
        return m('#filmAZ', {style: { backgroundColor: '#fff'}}, [
            html ? trust(html) : ''
        ]);
    }
};