import { m, redraw } from "mithril"
import { Button, Classes, Col, ControlGroup, Dialog, Grid, Icons, Input } from "construct-ui"
import Tabulator from "tabulator-tables"
import { get, post } from "../lib/fiona";
import header from "./header";

var grid = false,
filter = "",
list = [];

let locType = 'public';
let sortChanged = false;

// manual set sortOrder field with dialog
let sortDialogOpen = false;
let sortOrderId = '';
let sortOrderName = '';
let sortOrderValue = 0;
let sortOrderOldValue = 0;


const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(function(){
		}).catch(function(err){
			console.error(err);
		});
	}
}

const  syncData = async (force = false) => {
	if(!list.length || force == 'reload'){
        // console.log('refreshing list');
		grid.clearData();

		list = await get('locations_'+locType, {cache: force});
		setGridData();
	}else{
		setGridData();
	}

}

const onFind = (ev) => {
	filter = ev.target ? ev.target.value : ev;
	if(!filter){
		grid.deselectRow();
		return;
	}
	let filterExpression = filter.replace(' ', '.*');
	let find = new RegExp(filterExpression, 'i');
	let location = list.find( l => l.name.search(find) > -1 );
	if(location && location.sortOrder && location.sortOrder > -1){
		// console.log('found', location, location ? location.sortOrder : -1);
		let gridRow = grid.getRowFromPosition(location.sortOrder, true);
		if(gridRow){
			let gridRowPosition = grid.getRowFromPosition(location.sortOrder, true);
			grid.scrollToRow( grid.getRowFromPosition(location.sortOrder, true) );
			grid.deselectRow();
			grid.selectRow(gridRowPosition)
		}
	}else{
		grid.deselectRow();
	}
}

const sortRow = (id, oldPos, newPos) => {
	
	if(oldPos === newPos) return;
	sortChanged = true;
	let sortDown = false;
	if(newPos > oldPos) sortDown = true;
	for( let location of list){
		if(sortDown){
			if(location.sortOrder <= newPos && location.sortOrder > oldPos){
				location.sortOrder--;
			}
		}else{
			if(location.sortOrder >= newPos && location.sortOrder < oldPos){
				location.sortOrder++;
			}
		}

		if(location.id === id){
			location.sortOrder = newPos;
		}
	}

	setGridData();
	redraw();
	// grid.setSort()

}

const saveSort = async () => {

	let orderList = list.map( l => {
		return {id: l.id, sortOrder: l.sortOrder};
	})
	await post( 'saveLocationsSortOrder', { locations: orderList, type: locType});
	sortChanged = false;
}

const onSortCellDoublClick = (e, cell) => {

	sortOrderOldValue = cell._cell.value;
	sortOrderValue = cell._cell.value;
	sortOrderId = cell._cell.row.data.id;
	sortOrderName = cell._cell.row.data.name;

	sortDialogOpen = true;
	redraw();

	// console.log('cell dbl', sortOrderId, sortOrderValue);

}

export default {
	oncreate: ({attrs: {key = 'public'}}) => {
		list = [];
		locType = key;

		grid = new Tabulator("#grid", {

            responsiveLayout:  true,
			persistence: false,
			index: 'id',
			layout: "fitColumns",
			virtualDomBuffer: 100,
			height: '500px',
			headerSort:false,
			movableRows: true,

			rowMoved:function(row){
				let oldPos = row._row.data.sortOrder;
				let newPos = row.getPosition(true);
				let id = row._row.data.id;
				sortRow(id, oldPos, newPos);
			},
			columns: [
				{rowHandle:true, formatter:"handle", headerSort:false, frozen:true, width:30, minWidth:30},
				{title: "order", field: "sortOrder", sorter: "number", cellDblClick:function(e, cell){
					onSortCellDoublClick(e, cell);//e - the click event object
					//cell - cell component
				}},
				{title:"id", field:"id"},
				{title:"name", field:"name"},
				{title:"parent", field:"parentLocation.name", sorter: "string", formatter: "plaintext"},
				{title:"type", field:"type.key", formatter: "plaintext"},
                {title:"published", field:"publishedOn", formatter:"datetime", formatterParams:{
					outputFormat:"DD-MM-YYYY HH:mm",
					invalidPlaceholder:"(invalid date)",
					timezone:"Europe/Amsterdam",
				}},
                
			],
            initialSort: [
                { column: "sortOrder", dir: "asc" }
            ]

		});
		var filters = grid.getFilters();
		if(filters.length){
			filter = filters[0].value;
		}
		syncData();
	},
	view: () => {

		return m(Grid, [
            m(header),
			m(Dialog, {
				autofocus: true,
				closeOnEscapeKey: true,
				closeOnOutsideClick: true,
				isOpen: sortDialogOpen,
				onClose: (ev) => {
					// console.log('onclose')
					sortDialogOpen = false
				},
				title: `Set order number "${sortOrderName}"`,
				content: m(Input, {
					label: 'sort order',
					value: sortOrderValue,
					autofocus: true,
					onchange: (ev) => {
						sortOrderValue = ev.target.value;
					}
				}),
				footer: m(`.${Classes.ALIGN_RIGHT}`, [
					m(Button, {
						label: 'Close',
						onclick: () => { sortDialogOpen = false; }
					}),
					m(Button, {
						loading: false,
						label: 'Set',
						intent: 'primary',
						onclick: () => {
							sortRow(sortOrderId, sortOrderOldValue, sortOrderValue);
							sortDialogOpen = false;
							onFind(filter);
						}
					})
				])
			}),
			m(Col, {span: 12 }, [
				m('h2',locType+' Locations ['+list.length+']'),
                m(Button,{
					iconLeft: Icons.REFRESH_CW,
					label: "refresh",
					size: "xs",
					basic: true,
					onclick: () => {
						syncData('reload');
					}
				}),
				m(Button, {
					size: 'xs',
					intent: sortChanged ? 'positive' : 'default',
					leftIcon: Icons.SAVE,
					label: 'SAVE SORT ORDER',
					onclick: saveSort,
					disabled: !sortChanged
				})
            ]),
            m(Col, {span: 12 },
				m(Col, {span: 12 }, [
					m(ControlGroup, {
						style: 'margin-left: 20px;'
					},[
						m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFind}),
						// m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
					])
				]),
			    m('#grid')
            )
		]);

	}

};