import { m, route } from "mithril";
import composition from "../view/composition";
import compositions from "../view/compositions";
import editions from "../view/editions";
import editiontypes from "../view/editiontypes";
import film from "../view/film";
import films from "../view/films";
import locations from "../view/locations";
import show from "../view/show";
import shows from "../view/shows";
import ui from "../view/ui";
import lookups from "../view/lookups";
import blocks from "../view/blocks";
import downloads from "../view/downloads";

const routes = () => {
    route(document.getElementById("root"), "/", {
        "/": () => ui,
        "/editiontypes/": () => editiontypes,
        "/editions/": () => editions,
        "/films/": () => films,
        "/compositions/": () => compositions,
        "/shows/": () => shows,
        "/blocks/": () => blocks,
        "/show/": () => show,
        "/film/": () => film,
        "/composition/": () => composition,
        "/locations/:key/": locations,
        "/lookups/": () => lookups,
        "/downloads/": () => downloads

    });
}

export default routes;