import { m, route } from "mithril"
import { Button, Col, ControlGroup, Dialog, Grid, Icons, Input, Overlay } from "construct-ui"
import Tabulator from "tabulator-tables"
import { get } from "../lib/fiona";
import state from "../lib/state";
import header from "./header";
import rawObject from "./rawObject";
// import compositionAZ from "./renders/compositionAZ";
import filmAZ from "./renders/filmAZ";

var grid = false,
filter = "",
rawOpen = false,
list = [],
composition = {},
id;

const setGridData = () => {
	if(grid){
		grid.replaceData(list).then(() => {
		
		}).catch((err) => {
			console.error(err);
		});
	}
}

const  syncData = async (cache = true) => {
	if(!list.length || cache === 'reload'){
		list = [];
		grid.clearData();
		composition = await get('composition', {'compositionId': state.get('compositionId'), cache: cache });
		Object.keys(composition).forEach( (f, i) => {
			list.push({column: f, value: composition[f]});
		})
		setGridData();
	}else{
		setGridData();
	}

}

export default {
    oninit: () => {
        if(!state.get('compositionId')){
            route.set("/compositions/");
        }
		id = state.get('compositionId');
		if(!composition.id || id !== composition.id) list = [];
    },
	oncreate: () => {
        // console.log('compositionId', state.get('compositionId'));
		list = [];
		composition = {};

		grid = new Tabulator("#grid", {

            persistenceID:"composition",
            responsiveLayout:  true,
			persistence: false,
			headerSort: false,
			index: 'column',
			layout: "fitColumns",
			virtualDomBuffer:300,
			columns: [
				{title:"Column", field:"column", width: 200},
				{title:"Value", field:"value"}
			]
		});
		
		// var filters = grid.getFilters();
		// if(filters.length){
		// 	filter = filters[0].value;
		// }
		syncData();
	},
	view: () => {

		return [
			m(Dialog, {
				isOpen: rawOpen,
				title: 'RAW FIONA SOURCE VIEW',
				content: m(rawObject, {type: 'composition', id: id}),
				hasBackdrop: false,
				portalAttrs: {
					style: {
						width: "80vw"
					}
				},
				onClose: () => {
					rawOpen = false;
				}
			}),
			m(Grid, [
				m(header),
				m(Col, {span: 12 }, [
					m('h2',`composition: ${composition.fullTitle}`),
					m(Button,{
						iconLeft: Icons.REFRESH_CW,
						label: "refresh",
						size: "xs",
						basic: true,
						onclick: () => {
							syncData('reload');
						}
					}),
					m(Button,{
						iconLeft: Icons.EYE,
						label: "raw",
						size: "xs",
						basic: true,
						onclick: () => {
							rawOpen = true;
						}
					})
				]),
				// m(Col, {span: 12 }, [
				// 	m(ControlGroup, {
				// 		style: 'margin-left: 20px;'
				// 	},[
				// 		m(Input, {size: 'xs', value: filter, placeholder: 'Search name...', oninput: onFilter}),
				// 		m(Button, {size: 'xs', label: 'clear', onclick: resetFilter, disabled: filter.length ? false : true})
				// 	])
				// ]),
				m(Col, {span: 6 },
					m('#grid')
				),
				m(Col, {span: 6 },
					m(filmAZ, {
						key: composition.id,
						film: composition,
						lang: 'EN'
					})
					// m(filmAZ, {film: composition})
				)

			])
		];

	}

};